import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LoadingSpinner from '../../components/Loader';
import * as actions from './actions';
import { Question } from './Profile';
import { uploadImage } from '../../utils/helpers';
import Placeholders from './Placeholders';
import slugify from 'slugify';
import { bp } from '../../utils/mq';

export const defaultTemplates = {
  empty: {
    name: 'Blank Response',
    content: '',
  },
  flatRatePricing: {
    name: 'Flat Rate Pricing',
    content: `<p>Hi {{firstname}},</p><p>Thank you for requesting a quote for {{productName}}.</p><p>We work with over <strong>[insert number]</strong> {{institutionType}}s ranging from the small to very large and recognise that no two {{institutionType}}s are the same.</p><p>We offer a number of options for how you can get started using {{productName}} in order to suit the different requirements and budgets of {{institutionType}}s.</p><p>For a school like {{organisationName}} pricing can start from <strong>[insert pricing]</strong> per year.</p><p><strong>[Insert information about trials, discounts available or special offers you have]</strong></p><p>Would you like to arrange a demonstration or discuss your quotation in more detail? You can book straight into our calendar via this link <strong>[Insert Meeting Booking Link]</strong>.</p><p>I know how school can get busy, so if I don’t hear from you, I’ll send you a reminder in <strong>[insert time period]</strong>. My email will come from <strong>[insert email address]</strong> rather than through EdTech Impact, so please look out for that.</p><p><strong>[Insert signoff]</strong></p>`, 
    default: true,
  },
  licensePricing: {
    name: 'Licence Pricing',
    content: `<p>Hi {{firstname}},</p><p>Thank you for requesting a quote for {{productName}}.</p><p>We work with over <strong>[insert number]</strong> {{institutionType}}s ranging from the small to very large and recognise that no two {{institutionType}}s are the same.</p><p>We offer a number of options for how you can get started using {{productName}} in order to suit the different requirements and budgets of {{institutionType}}s.</p><p>The price for {{organisationName}} is dependent on the number of <strong>[students / staff]</strong> who will be using  {{productName}}. Pricing starts from <strong>[insert pricing]</strong> per user for example.</p><p><strong>[Insert information about trials, discounts available or special offers you have]</strong></p><p>In order for me to provide you with an accurate quotation, can we arrange a time to speak so I can fully understand your requirements and provide you with the best price possible?</p><p>You can book straight into my calendar via this link <strong>[Insert Meeting Booking Link]</strong>.</p><p>I know how school can get busy, so if I don’t hear from you, I’ll send you a reminder in <strong>[insert time period]</strong>. My email will come from <strong>[insert email address]</strong> rather than through EdTech Impact, so please look out for that.</p><p><strong>[Insert signoff]</strong></p>`,
    default: true,
  },
  demo: {
    name: 'Demo',
    content: `<p>Hi {{firstname}},</p><p>Thank you for requesting a demo for {{productName}}.</p><p>We work with over <strong>[insert number]</strong> {{institutionType}}s ranging from the small to very large and it will be great to show what we can do for {{organisationName}}.</p><p>To arrange a demonstration, please find a time and book straight into our calendar via this link <strong>[Insert Meeting Booking Link]</strong>.</p><p><strong>[Insert information about trials, discounts available or special offers you have]</strong></p><p>I know how school can get busy, so if I don’t hear from you, I’ll send you a reminder in [insert time period]. My email will come from <strong>[insert email address]</strong> rather than through EdTech Impact, so please look out for that.</p><p><strong>[Insert signoff]</strong></p>`,
    default: true,
  },
  trialSelfServe: {
    name: 'Trial (Self Serve)',
    content: `<p>Hi {{firstname}},</p><p>Thank you for requesting a trial for {{productName}}.</p><p>We work with over <strong>[insert number]</strong> {{institutionType}}s ranging from the small to very large and it will be great to set {{organisationName}} up on a trial.</p><p>To get started, all you need to do is go to <strong>[Insert Link]</strong> and <strong>[Insert Instructions]</strong>.</p><p>I’ll keep a lookout to make sure you sign up. If you haven’t got started within <strong>[insert time period]</strong>, I’ll check in to see you’re doing okay. My email will come from <strong>[insert email address]</strong> rather than through EdTech Impact, so please look out for that.</p><p><strong>[Insert signoff]</strong></p>`,
    default: true,
  },
  trialMeetingNeeded: {
    name: 'Trial (Meeting Needed)',
    content: `<p>Hi {{firstname}},</p><p>Thank you for requesting a trial for {{productName}}.</p><p>We work with over <strong>[insert number]</strong> {{institutionType}}s ranging from the small to very large and it will be great to set up a trial for {{organisationName}}.</p><p>To start your trial, we need a bit more information, so can we arrange a time to speak? You can book a time straight into our calendar via this link <strong>[Insert Meeting Booking Link]</strong>.</p><p>I know how school can get busy, so if I don’t hear from you, I’ll send you a reminder in <strong>[insert time period]</strong>. My email will come from <strong>[insert email address]</strong> rather than through EdTech Impact, so please look out for that.</p><p><strong>[Insert signoff]</strong></p>`,
    default: true,
  },
};

const LeadReplyTemplates = ({ product, updatePending, dispatchSubmit }) => {

  const [activeTab, setActiveTab] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const [unsaved, setUnsaved] = useState(false);
  
  useEffect(() => {
    let allTemplates = {
      ...defaultTemplates,
      ...(product.templates || {}),
    };
    Object.keys(allTemplates).forEach((property, index) => {
      if (index === 0) {
        setActiveTab(property);
      }
      allTemplates = {
        ...allTemplates,
        [property]: {
          ...allTemplates[property],
          content: EditorState.createWithContent(convertFromHTML(allTemplates[property].content)),
        },
      }
      setTemplates(allTemplates);
    });
    setUnsaved(false);
  }, [product]);
  
  const processTemplatesForSubmit = templates => {
    const processedTemplates = {};
    for (const templateId in templates) {
      let html = convertToHTML(templates[templateId].content.getCurrentContent());
      if (html === '<p></p>') { html = ''};
      processedTemplates[templateId] = {
        ...templates[templateId],
        content: html,
      };

      if (processedTemplates.new && newTitle) {
        processedTemplates[slugify(newTitle)] = {
          name: newTitle,
          content: html,
        }
      }

      delete processedTemplates.new;
    }
    return processedTemplates;
  }

  const handleAddNew = () => {
    setTemplates({
      ...templates,
      new: {
        title: 'new',
        content: EditorState.createWithContent(convertFromHTML('<p></p>')),
      },
    });
  };

  const deleteTemplate = templateId => {
    const newTemplates = {...templates};
    delete newTemplates[templateId];
    setTemplates(newTemplates);
    setActiveTab(Object.keys(newTemplates)[0]);
    const product = {
      templates: processTemplatesForSubmit(newTemplates),
    };
    dispatchSubmit({product});
  }

  const handleSubmit = e => {
    e.preventDefault();

    const product = {
      templates: processTemplatesForSubmit(templates),
    };
    
    dispatchSubmit({product});
    setNewTitle('');
  };

  if (!templates) return <LoadingSpinner />;

  return (
    <StyledLeadReplyTemplates fluid>
      {updatePending && <LoadingScreen><LoadingSpinner /></LoadingScreen>}

      <h4 className="font-size-24 mb-4">Message Reply Templates</h4>

      <Tabs tabs className="mb-2">
        {Object.keys(templates)
          .filter(templateId => templateId !== 'new')
          .map(templateId => (
            <Tab key={templateId}>
              <NavLink
                className={activeTab === templateId ? 'active' : ''}
                onClick={() => setActiveTab(templateId)}
              >
                {templates[templateId].name}
              </NavLink>
            </Tab>
        ))}
        <Tab>
          <NavLink
            className={activeTab === 'new' ? 'active' : ''}
            onClick={() => {
              handleAddNew();
              setActiveTab('new');
            }}
          >
            + Add New
          </NavLink>
        </Tab>
      </Tabs>

      <TabContent activeTab={activeTab}>
        {Object.keys(templates).map(templateId => (
          <TabPane key={templateId} tabId={templateId}>
            <div className="mb-1 d-flex align-items-center" style={{color: 'red', height: '2rem'}}>
              {unsaved ?
                <>
                  You have unsaved changes
                  <Button onClick={handleSubmit} className="ml-2" color="primary" type="submit" size="sm">
                    Save
                  </Button>
                </>
                :
                <>&nbsp;</>
              }
            </div>
            <Row>
              <Col>
                {templateId === 'new' ? (
                  <Question
                    title="Name"
                    description="Enter a unique name for your template."
                    width={9}
                    style={{marginBottom: '-1rem', boxShadow: 0}}
                    Input={() => <Input
                      type="text"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                    />}
                  />
                ) : (
                <Row>
                  <Col md={9}>
                      <Button
                        className="position-absolute"
                        style={{zIndex: 9, right: '2.25rem', top: '18px'}}
                        size="sm"
                        color="danger"
                        onClick={() => deleteTemplate(templateId)}>
                          {defaultTemplates[templateId] ? 'Restore Default' : 'Delete'}
                      </Button>
                  </Col>
                </Row>
                )}
                <Question
                  title={templates[templateId].name}
                  description={templates[templateId].description}
                  width={9}
                  style={{marginBottom: '1rem'}}
                  Input={() => <Editor
                    editorState={templates[templateId].content}
                    onEditorStateChange={content => {
                      const hasEditedDefault = defaultTemplates[templateId] && defaultTemplates[templateId].content !== convertToHTML(content.getCurrentContent());
                      const hasEdited = templates[templateId] && convertToHTML(templates[templateId].content.getCurrentContent()) !== convertToHTML(content.getCurrentContent());
                      setUnsaved(unsaved || hasEdited);
                      setTemplates({
                        ...templates,
                        [templateId]: {
                          ...templates[templateId],
                          content,
                          default: !hasEditedDefault,
                        },
                      })
                    }}
                    toolbarCustomButtons={[<Placeholders />]}
                    toolbar={{
                      options: ['inline', 'blockType', 'history', 'list', 'textAlign', 'link', 'emoji', 'image'],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                      image: {
                        uploadCallback: async file => {
                          return new Promise((resolve, reject) => {
                            uploadImage(file).then(link => {
                              resolve({data: {link} });
                            });
                          })
                        },
                        previewImage: true,
                      },
                    }}
                  />}
                />
              </Col>
            </Row>
          </TabPane>
        ))}

        <Button onClick={handleSubmit} color="primary" type="submit">
          Save All
        </Button>
      </TabContent>

    </StyledLeadReplyTemplates>
  );
}

export const Tabs = styled(Nav)`
  margin-bottom: 1rem;
  display: block;

  @media ${bp.md} {
    display: flex;
  }
`;

export const Tab = styled(NavItem)`
  cursor: pointer;

  .nav-link.active {
    background: transparent;
    border-color: transparent;
  }

  &:hover {
    .nav-link {
      border-color: transparent;  
      background: transparent;
    }
  }

  .active {
    border-bottom: 4px solid #FD7252 !important;
    cursor: initial;
    font-weight: bold;
  }
`;

const LoadingScreen = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(255,255,255,0.6);
  z-index: 999;
`;

const StyledLeadReplyTemplates = styled(Container)`
  position: relative;
  margin-bottom: 3rem;
  margin-top: 2rem;

  .placeholder-ul{
    visibility: hidden;
  }
  .placeholder-li:hover {
    background: #F1F1F1;
  }

  .form-check {
    display: inline-block;
    width: 12rem;
  }

  .dropzone {
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }

  .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
  };

  .dz-details {
    img {
      height: 100px;
    }
  }

  .dropzone {
    display: block;
    text-align: center;

    .dz-preview {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin: 16px;
      min-height: 100px;
    }
  }
  
  
  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
  };
  
  .thumbInner {
    display: flex;
    minWidth: 0;
    overflow: hidden;
  };
  
  .img {
    display: block;
    width: auto;
    height: 100%;
  };

  .rdw-editor-main {
    padding-top: 8rem;
  }
`;

const mapStateToProps = state => {
  const { updatePending, product } = state.Product;
  return { updatePending, product };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchSubmit: actions.updateProductAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(LeadReplyTemplates);
