import React, { Component, createRef, useState } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import Immutable from 'seamless-immutable';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromHTML, convertToHTML } from 'draft-convert';
import Loader from '../../components/Loader';
import * as actions from './actions';
import SelectSearch from 'react-select-search';
import { videoToString } from '../../utils/helpers';
import RUG, { DropArea, Card as RUGCard } from 'react-upload-gallery'
import 'react-upload-gallery/dist/style.css' // or scss
import slugify from 'slugify';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import countries from './data/countries';
import languages from './data/languages';
import { withTracking } from 'react-tracking';
import { toast } from "react-toastify";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import Select, {
  components,
} from 'react-select';
import PremiumFeature from '../../components/PremiumFeature';
import { Alert as MuiAlert, Box, Card, CardContent, Stack, Typography, Button as MuiButton, Link, AlertTitle } from '@mui/material';
import { CheckBoxRounded, CloudOffRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import AdminFeature from 'components/AdminFeature';

export const profileFields = [
  { name: "slug" },
  { name: "name", required: true },
  { name: "searchTerms", required: true, isPremium: true },
  { name: "logoImages", required: true },
  { name: "companyName", required: true },
  { name: "founded", required: true },
  { name: "country", required: true },
  { name: "homepage", required: true, isPremium: true },
  { name: "twitter", required: false, isPremium: true },
  { name: "linkedIn", required: false, isPremium: true },
  { name: "phone", required: false, isPremium: true },
  { name: "shortDescription", required: false },
  { name: "story", required: true },
  { name: "ageRange", required: true },
  { name: "screenshots", required: true },
  { name: "video", required: false, isPremium: true },
  { name: "tags", required: true, isPremium: true },
  { name: "heroFeatures", required: true },
  { name: "accessibility", required: true },
  { name: "languages", required: true },
  { name: "dataPrivacy", required: true },
  { name: "terms", required: true },
  { name: "gdpr", required: false },
  { name: "requirements", required: true },
  { name: "techRequirements", required: false },
  { name: "trialSetUp", required: true },
  { name: "trainingSelection", required: true },
  { name: "training", required: false },
  { name: "techSupport", required: true },
  { name: "supportDetails", required: false },
  { name: "availableToParents", required: true },
  { name: "initiator", required: false },
  { name: "supervision", required: false },
  { name: "purchaseModels", required: true },
  { name: "priceStartingFrom", required: true },
  { name: "additionalPricingInformation", required: false },
  { name: "specialOffer", required: false, isPremium: true },
  { name: "availableToParentsDetails", required: false },
];

const wysiwygFields = ['supportDetails', 'availableToParentsDetails', 'story', 'usage', 'training', 'trialSetUp', 'techRequirements', 'additionalPricingInformation'];
const initialState = {
  heroFeatures: [],
  searchTerms: [],
  tags: [],
  activeTab: '1',
  demoLink: '',
  story: '',
  shortDescription: '',
  homepage: '',
  twitter: '',
  linkedIn: '',
  phone: '',
  companyName: '',
  dataPrivacy: '',
  specialOffer: '',
  techRequirements: '',
  requirements: [
    { title: 'Available Offline', selected: false },
    { title: 'Internet - Low Bandwidth', selected: false },
    { title: 'Internet - High Bandwidth', selected: false },
    { title: 'Desktop - Mac', selected: false },
    { title: 'Desktop - Windows', selected: false },
    { title: 'Desktop - Chromebook', selected: false },
    { title: 'Desktop - Linux', selected: false },
    { title: 'Mobile - iPhone', selected: false },
    { title: 'Mobile - iPad', selected: false },
    { title: 'Mobile - Windows', selected: false },
    { title: 'Mobile - Android', selected: false },
    { title: 'Radio', selected: false },
    { title: 'Television', selected: false },
  ],
  languages: [],
  techSupport: [
    {
      title: 'Email/Help Desk',
      selected: false,
    },
    {
      title: 'FAQs/Forum',
      selected: false,
    },
    {
      title: 'Knowledge Base',
      selected: false,
    },
    {
      title: 'Phone Support',
      selected: false,
    },
    {
      title: '24/7 Live Rep',
      selected: false,
    },
  ],
  terms: '',
  training: '',
  trainingSelection: [
    { title: 'In Person', selected: false },
    { title: 'Live Online', selected: false },
    { title: 'Webinars', selected: false },
    { title: 'Documentation', selected: false },
    { title: 'Videos', selected: false },
  ],
  trialSetUp: '',
  usage: '',
  logo: null,
  images: null,
  purchaseModels: [
    {
      key: 'Free',
      title: 'Free',
      selected: false,
    },
    {
      key: 'Freemium',
      title: 'Freemium',
      selected: false,
    },
    {
      key: 'Free Trial',
      title: 'Free Trial',
      selected: false,
    },
    {
      key: 'Paid Subscription',
      title: 'Paid Subscription',
      selected: false,
    },
    {
      key: 'One-Off Fee',
      title: 'One-Off Fee',
      selected: false,
    },
    {
      key: 'Hourly Rate',
      title: 'Hourly Rate',
      selected: false,
    },
  ],
  ageRange: [
    {
      key: '0-4',
      title: '0-4 years old',
      selected: false,
    },
    {
      key: '5-7',
      title: '5-7 years old',
      selected: false,
    },
    {
      key: '8-10',
      title: '8-10 years old',
      selected: false,
    },
    {
      key: '11-13',
      title: '11-13 years old',
      selected: false,
    },
    {
      key: '14-16',
      title: '14-16 years old',
      selected: false,
    },
    {
      key: '17-18',
      title: '17-18 years old',
      selected: false,
    },
    {
      key: '19+',
      title: '19 and older',
      selected: false,
    },
  ],
  supervision: false,
  additionalPricingInformation: '',
  video: '',
  name: '',
  priceStartingFrom: {},
  pricingPage: '',
  availableToParentsDetails: '',
  accessibility: '',
  gdpr: '',
  supportDetails: '',
  founded: '',
  availableToParents: '',
  initiator: '',
  showErrors: false,
};

const currentYear = (new Date()).getFullYear();
const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
const hasError = (required, disabled, value) => required && !disabled && ((typeof value === 'undefined' || value === '' || value === '<p></p>' || (Array.isArray(value) && (value.length === 0 || value.filter(v => v?.selected === false).length === value.length)) || (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)));

/**
 * @typedef {Object} RUGUpload
 * @property {String} source - The URL of the image. Can be a complete URL or a blob URI.
 * @property {Number} size - Size in bytes. Available only if it is a new file yet to be uploaded, otherwise 0.
 * @property {Boolean} done
 * @property {String} name
 * @property {Boolean} uploading
 * @property {File} file
 */

/**
 * @typedef {Object} ProductState
 * @property {RUGUpload[]} screenshots
 */

/**
 * @typedef {Object} ProductProps
 * @property {String[]} images URLs of all images
 */

export class ProductAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.anyScreenshotErrorToast = createRef();
  }

  componentDidMount = () => {
    const { product } = this.props;

    if (product) {
      this.setStateFromProps(product, initialState);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { product } = nextProps;

    if (!this.props?.product && product || (product && product.slug !== this.props?.product.slug)) {
      // this.props.dispatchResetState();
      this.setStateFromProps(product, initialState);
    }
  }

  setStateFromProps = (product, oldState) => {

    this.setState(oldState, () => {

      Object.keys(product).forEach(property => {

        if (['purchaseModels'].includes(property)) {
          const purchaseModels = product.purchaseModels.map(model => {
            if (['Monthly Subscription', 'Annual Subscription'].includes(model)) return 'Paid Subscription';
            return model;
          })

          const newProperty = this.state[property].map(x => {
            if (purchaseModels && purchaseModels.includes(x.key)) return { ...x, selected: true };
            return { ...x, selected: false };
          });
          this.setState({ [property]: newProperty });

        } else if (['tags'].includes(property)) {
          this.setState({ [property]: Immutable.asMutable(product[property]) });

        } else if (property === 'searchTerms') {
          this.setState({ searchTerms: Array.isArray(product.searchTerms) ? Immutable.asMutable(product.searchTerms) : product.searchTerms.split(',') });

        } else if (['requirements', 'trainingSelection'].includes(property)) {
          const newProperty = this.state[property].map(x => {
            if (product[property] && product[property].includes(x.title)) return { ...x, selected: true };
            return { ...x, selected: false };
          });
          this.setState({ [property]: newProperty });

        } else if (['ageRange'].includes(property)) {

          const newProperty = this.state[property].map(x => {
            if (product[property] && product[property].includes(x.key)) return { ...x, selected: true };
            return { ...x, selected: false };
          });
          this.setState({ [property]: newProperty });

        } else if (wysiwygFields.includes(property)) {
          this.setState({ [property]: EditorState.createWithContent(convertFromHTML(product[property])) });

        } else if (property === 'techSupport') {
          const techSupport = this.state.techSupport.map(item => {
            const support = Array.isArray(product[property]) ? product[property] : product[property].split(',');
            if (support.map(item => item.trim()).includes(item.title)) return { ...item, selected: true };
            return { ...item, selected: false };
          });
          this.setState({ techSupport });

        } else if (['true', 'false', true, false].includes(product[property])) {
          this.setState({ [property]: ['true', true].includes(product[property]) ? 'Yes' : 'No' });

        } else if (property === 'video') {
          this.setState({ video: videoToString(product.video) });

        } else if (['logo'].includes(property)) {
          this.setState({ [property]: [product[property].url_126] });

        } else if (['heroFeatures'].includes(property)) {
          this.setState({ [property]: Immutable.asMutable(product[property]) });

        } else if (['languages'].includes(property)) {
          this.setState({
            [property]: product[property].map(l => ({
              value: l, label: l,
            }))
          });

        } else {
          this.setState({ [property]: product[property] });
        }
      });

      if (!product.images) this.setState({ images: [] });
      if (!product.logo) this.setState({ logo: [] });
      if (product.slug === 'add-listing') this.setState({ name: '' });
    });
  }

  processProductForSubmit = product => {

    for (const property in product) {
      if (!profileFields.find(field => field.name === property)) {
        delete product[property];

      } else if (property === 'priceStartingFrom' && product[property].amount !== 0 && (!product[property].currency || !product[property].amount || !product[property].period)) {
        product[property] = null;

      } else if (product[property] && wysiwygFields.includes(property)) {
        let html = convertToHTML(product[property].getCurrentContent());
        if (html === '<p></p>') { html = '' };
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(html, 'text/html');
        const containsLinks = htmlDoc.getElementsByTagName('a').length > 0;

        if (containsLinks) {
          return false;
        }

        product[property] = html;

      } else if (['ageRange', 'purchaseModels', 'subjects'].includes(property)) {
        product[property] = product[property].filter(x => x.selected).map(x => x.key);

      } else if (['techSupport', 'requirements', 'trainingSelection'].includes(property)) {
        product[property] = product[property].filter(x => x.selected).map(x => x.title);

      } else if ('languages' === property) {
        product[property] = product[property].map(x => x.value);

      } else if (!['numOfReviews'].includes(property) && ['Yes', 'No'].includes(product[property])) {
        product[property] = product[property] === 'Yes';
      }
    }
    return product;
  }

  getScreenshotSize = async (screenshot) => {
    try {
      const res = await fetch(screenshot, {
        method: 'HEAD',
        headers: {
          'Cache-Control': 'no-cache',
        }
      })

      if (!res.ok) {
        console.warn(`Failed to get size for screenshot: ${screenshot}`)
        return 0
      }

      const size = Number(res.headers.get("content-length"))
      return isNaN(size) ? 0 : size
    } catch (error) {
      console.warn(`Error getting size for screenshot: ${screenshot}`, error)
      return 0
    }
  };

  handleSubmit = () => async () => {
    /**
     * Look at `setStateFromProps`
     * @type {ProductState}
     */
    const stateProduct = { ...this.state };
    const {
      logoImages,
      screenshots: currentScreenshots,
    } = stateProduct;

    /**
     * Redux product (as-is from the API)
     * @type {ProductProps}
     */
    const { images: existingScreenshots } = this.props.product;
    const { isAdmin } = this.props;

    const logo = logoImages[0]?.file ? logoImages[0] : null;

    const hasExistingScreenshots = existingScreenshots && Array.isArray(existingScreenshots) && !!existingScreenshots.length
    let screenshotsHasChanges = false
    if (!hasExistingScreenshots && !isAdmin) {
      // Since screenshots is a required field, and there are no existing screenshots, a typical user will need to upload some files here. We will always have changes.
      screenshotsHasChanges = true
    } else if (currentScreenshots?.length !== existingScreenshots?.length) {
      screenshotsHasChanges = true
    } else {
      // Final check: do all the URLs match?
      screenshotsHasChanges = currentScreenshots?.reduce((hasChanges, currentScreenshot) => {
        return hasChanges || !existingScreenshots.some(es => es === currentScreenshot.source);
      }, false);
    }


    /**
     * Changes to handle now (possibly nothing, too)
     * @type {RUGUpload[] | null}
     */
    const screenshots = screenshotsHasChanges && Array.isArray(currentScreenshots) && !!currentScreenshots.length
      ? currentScreenshots
      : null;

    /**
     * They may have removed some screnshots from existing screenshots. Let's figure out what has been retained and only calculate the size of those towards storage space usage.
     * @type {String[]}
     **/
    const retainedScreenshots = []
    let retainedScreenshotsSizeInBytes = 0
    // No point calculating these if there are no changes! A size check is only relevant if made when we have updates. If no changes, we can safely assume the previous check ran correctly and was handled then.
    if (hasExistingScreenshots && screenshotsHasChanges) {
      for (const es of existingScreenshots) {
        if (currentScreenshots.some(cs => es === cs.source)) {
          retainedScreenshots.push(es)
        }
      }

      try {
        // Ideally we should be warning users if retained uploads exceed 4 MB, but that will cause a lot of unnecessary work and frustration. So leaving it be for now. However, this can still be helpful when checking for the total screenshots size when there are changes to screenshots.
        const retainedScreenshotsSizePromises = retainedScreenshots.map(screenshot => this.getScreenshotSize(screenshot))

        const retainedScreenshotsSizes = await Promise.all(retainedScreenshotsSizePromises)

        retainedScreenshotsSizeInBytes = retainedScreenshotsSizes.reduce((total, current) => total + current, 0)

        // Leaving this in for now to help with debugging if needed via LogRocket.
        console.log({
          existingScreenshots,
          currentScreenshots,
          retainedScreenshots,
          retainedScreenshotsSizeInBytes,
          screenshotsHasChanges
        })
      } catch (error) {
        // Proceed with assumed size of 0. Just log the error here.
        console.error('Error calculating total screenshot sizes:', error);
      }
    }

    for (const propertyDefinition of this.buildProfileFields(stateProduct).filter(field => !field.isPremium || this.props.product.isPremium)) {
      const fieldNotCompleted = hasError(propertyDefinition.required, false, stateProduct[propertyDefinition.name]);

      if (fieldNotCompleted && !isAdmin) {
        console.log(stateProduct[propertyDefinition.name])
        toast.error(`Error. ${propertyDefinition.title} is required.`);
        document.getElementById(propertyDefinition.name)?.scrollIntoView();
        this.setState({ showErrors: true });
        return;
      }
    }

    // First, dismiss any existing screenshots toast.
    toast.dismiss(this.anyScreenshotErrorToast.current);

    if (!currentScreenshots?.length && !isAdmin) {
      this.anyScreenshotErrorToast = toast.error("At least one screenshot is required.", {
        autoClose: 10000,
        hideProgressBar: false,
      });
      document.getElementById('screenshots')?.scrollIntoView();
      this.setState({ showErrors: true });
      return;
    }


    if (screenshots?.length) {
      if (screenshots.length > 5) {
        this.anyScreenshotErrorToast = toast.error("Select no more than 5 screenshots. Then try submitting again.", {
          autoClose: 10000,
          hideProgressBar: false,
        });
        document.getElementById('screenshots')?.scrollIntoView();
        this.setState({ showErrors: true });
        return;
      }

      const newImages = screenshots.filter(i => !i.done);

      for (const newImage of newImages) {
        const index = screenshots.indexOf(newImage)
        const sizeInBytes = newImage.file.size || 0
        if ((sizeInBytes / 1024) > 1024) {
          this.anyScreenshotErrorToast = toast.error(`Screenshot ${index + 1} is too large. Use a smaller or a compressed version. Then try submitting again.`, {
            autoClose: 10000,
            hideProgressBar: false,
          });
          document.getElementById('screenshots')?.scrollIntoView();
          this.setState({ showErrors: true });
          return;
        }
      }

      // This won't take into account the size of already uploaded images.
      const totalImageSizeInBytes = screenshots.reduce((total, image) => image.file ? total + image.file.size : total, 0);

      if (((totalImageSizeInBytes + retainedScreenshotsSizeInBytes) / 1024) > 4096) {
        this.anyScreenshotErrorToast = toast.error("The total size of all the screenshots put together is too large (greater than 4 MB). Please update your screenshots selection, then try submitting again.", {
          autoClose: 10000,
          hideProgressBar: false,
        });
        document.getElementById('screenshots')?.scrollIntoView();
        this.setState({ showErrors: true });
        return;
      }
    }

    const processedProduct = this.processProductForSubmit(stateProduct);

    if (!processedProduct) {
      toast.error("Error. Fields must not contain links.");
      return;
    }

    if (!stateProduct.slug || stateProduct.slug === 'add-listing') {
      this.props.dispatchCreateProduct({
        product: processedProduct,
        screenshots,
        logo,
      });
      this.props.tracking.trackEvent({ event: 'create-product' });

    } else {
      this.props.dispatchSubmit({
        product: processedProduct,
        screenshots,
        logo,
      });
    }
  };

  buildProfileFields = product => {
    const {
      story,
      techRequirements,
      training,
      trialSetUp,
      additionalPricingInformation,
      logo,
      images: uploadedImageUrls,
      priceStartingFrom,
      availableToParents,
      initiator,
      accessibility,
      heroFeatures,
      founded,
      country,
      supportDetails,
      availableToParentsDetails,
      searchTerms,
      tags,
      supervision,
    } = this.state;

    const handleCheckboxClick = (property, key, keyProperty = 'key') => () => {
      const newProperty = this.state[property].map(x => {
        if (x[keyProperty] === key) return { ...x, selected: !x.selected };
        return x;
      });
      this.setState({ [property]: newProperty });
    }

    const renderTechSupport = () =>
      this.state.techSupport.map(({ title, selected }) => {
        const key = slugify(title);
        return (
          <FormGroup check key={key}>
            <Input
              id={key}
              type="checkbox"
              checked={selected}
              onChange={handleCheckboxClick('techSupport', title, 'title')}
            />
            <Label check for={key}>
              <LabelText>{title}</LabelText>
            </Label>
          </FormGroup>
        )
      });

    const renderRequirements = () =>
      this.state.requirements.map(({ title, selected }) => {
        const key = slugify(title);
        return (
          <FormGroup check key={key}>
            <Input
              id={key}
              type="checkbox"
              checked={selected}
              onChange={handleCheckboxClick('requirements', title, 'title')}
            />
            <Label check for={key}>
              <LabelText>{title}</LabelText>
            </Label>
          </FormGroup>
        );
      });

    const renderTrainingSelection = () =>
      this.state.trainingSelection.map(({ title, selected }) => {
        const key = slugify(title);
        return (
          <FormGroup check key={key}>
            <Input
              id={key}
              type="checkbox"
              checked={selected}
              onChange={handleCheckboxClick('trainingSelection', title, 'title')}
            />
            <Label check for={key}>
              <LabelText>{title}</LabelText>
            </Label>
          </FormGroup>
        );
      });

    const renderPurchaseModels = () =>
      this.state.purchaseModels.map(({ key, title, selected }) => (
        <FormGroup check key={key}>
          <Input
            id={key}
            type="checkbox"
            checked={selected}
            onChange={handleCheckboxClick('purchaseModels', key)}
          />
          <Label check for={key}>
            <LabelText>{title}</LabelText>
          </Label>
        </FormGroup>
      ));

    const renderAges = () =>
      this.state.ageRange.map(({ key, title, selected }) => (
        <FormGroup check key={key}>
          <Input
            id={key}
            type="checkbox"
            checked={selected}
            onChange={handleCheckboxClick('ageRange', key)}
          />
          <Label check for={key}>
            <LabelText>{title}</LabelText>
          </Label>
        </FormGroup>
      ));

    const handleFieldChange = field => value => {
      this.setState({ [field]: value });
    }

    const SortableMultiValue = SortableElement((props) => {
      const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
      };
      const innerProps = { ...props.innerProps, onMouseDown };
      return <components.MultiValue {...props} innerProps={innerProps} />;
    });

    const SortableMultiValueLabel = SortableHandle(props => <components.MultiValueLabel {...props} />);

    const SortableSelect = SortableContainer(Select);

    const profileFieldDefinitions = (
      [
        { name: "slug" },
        { name: "name", title: "Product Name", description: () => <>The name of your product as it appears on your website (without any strap lines or keywords).</>, type: "text" },
        {
          name: "searchTerms",
          title: "Alternative Names",
          description: () => <>Help potential customers find you by listing names by which your product might be known (e.g. {product.name.replace(' ', '')}, {product.name.replace(' ', '')}.com etc.)</>,
          helpText: "Type an alternative name, then press enter to continue adding more.",
          input: () => <ReactTagInput
            placeholder={true}
            tags={searchTerms}
            maxTags={20}
            editable={false}
            readOnly={false}
            removeOnBackspace={true}
            onChange={(searchTerms) => this.setState({ searchTerms })}
          />
        },
        {
          name: "logoImages", title: "Logo", description: () => <>Please use a SQUARE image (130x130px is perfect, max  size 2MB).</>, input: () => (
            <RUG
              autoUpload={false}
              onChange={(images) => {
                this.setState({ logoImages: images }) // save current component
              }}
              rules={{
                limit: 1,
                size: 2048,
              }}
              initialState={logo.length ? [{ source: logo[0], name: '' }] : undefined}
              header={({ openDialogue }) => (
                <DropArea>
                  {(isDrag) => (
                    <DropZone onClick={openDialogue}>
                      Drag or click to select file
                    </DropZone>
                  )}
                </DropArea>
              )}
            />
          )
        },
        { name: "companyName", title: "Company Name", description: () => <>The official name of your company (can be the same as your product name).</>, type: "text" },
        {
          name: "founded", title: "Year Founded", description: () => <>The year your product launched to the world.</>, input: () => <FormGroup>
            <SelectSearch
              options={[{ name: '', value: '' }, ...range(currentYear, currentYear - 300, -1).map(year => ({ name: year, value: year }))]}
              onChange={handleFieldChange('founded')}
              value={founded}
            />
          </FormGroup>
        },
        {
          name: "country", title: "HQ Location", input: () => <FormGroup>
            <SelectSearch
              options={[{ name: '', value: '' }, ...countries.map(country => ({ name: country.label, value: country.label }))]}
              onChange={handleFieldChange('country')}
              value={country}
            />
          </FormGroup>
        },

        { name: "homepage", title: "Company Website", description: () => <>Your company homepage e.g. edtechimpact.com</>, type: 'text' },
        { name: "twitter", title: "Twitter", description: () => <>Your twitter handle e.g. @EdTechImpact</>, type: 'text' },
        { name: "linkedIn", title: "LinkedIn", type: 'text', description: () => <>Your linkedIn page e.g. linkedin.com/company/edtechimpact</> },
        { name: "phone", title: "Phone Number", type: 'text', description: () => <>Your phone number in international format e.g. +44 191 123 4567</> },
        { name: "shortDescription", forAdmin: true, title: "Category Description", description: () => <>A short description that quickly tells the user what your product does and how it will help them.</>, type: "textarea" },
        {
          name: "story", title: "Full Description", description: () => <>Imagine you are speaking to someone that isn’t familiar with your product at all. Be specific and concise. Use paragraphs.</>, input: () => <Editor
            editorState={story}
            onEditorStateChange={story => this.setState({ story })}
            toolbar={{
              options: ['inline', 'history', 'link'],
              inline: {
                options: ['bold', 'italic', 'underline'],
              },
            }}
          />
        },
        { name: "ageRange", title: "Age Groups", description: () => <>Select all age groups that your product supports.</>, input: renderAges },
        {
          name: "screenshots", title: "Screenshots", description: () => <>Choose up to 5 screenshots (max image size 1MB, max total size 4MB)</>, input: () => (
            <RUG
              autoUpload={false}
              onChange={screenshots => {
                this.setState({ screenshots })

                const existingScreenshots = screenshots.filter(s => s.done && s.source)
                const newScreenshots = screenshots.filter(s => !!s.file)

                const existingScreenshotsSizeInBytes = existingScreenshots.reduce(async (total, screenshot) => {
                  // Make a head request and look at the file size headers.
                  const res = await fetch(screenshot.source, { headers: { method: 'HEAD' } })
                  if (res.ok) return total + Number(res.headers.get("content-length"))
                  return total + 0
                }, 0)

                const newScreenshotsSizeInBytes = newScreenshots.reduce((total, screenshot) => total + screenshot.file.size, 0);

                if ((newScreenshotsSizeInBytes + existingScreenshotsSizeInBytes) / 1024 > 4096) {
                  this.anyScreenshotErrorToast = toast.error("The total size of all the screenshots put together is too large (greater than 4 MB). Please update your screenshots selection.", {
                    autoClose: 10000,
                    hideProgressBar: false,
                  });
                }
              }}
              rules={{
                limit: 5,
                size: 1024,
              }}
              accept={['jpg', 'jpeg', 'png', 'gif']}
              initialState={[...uploadedImageUrls].map(image => ({ source: image, name: '' }))}
              onWarning={(type, rules) => {
                toast.dismiss(this.anyScreenshotErrorToast.current)
                switch (type) {
                  case 'accept':
                    this.anyScreenshotErrorToast = toast.error("Only .jpg, .jpeg, and .png files are allowed.", {
                      autoClose: 10000,
                      hideProgressBar: false,
                    });
                    break
                  case 'limit':
                    this.anyScreenshotErrorToast = toast.error("No more than 5 screenshots can be selected at a time. Try removing an existing screenshot if you need to another one.", {
                      autoClose: 10000,
                      hideProgressBar: false,
                    });
                    break
                  case 'size':
                    this.anyScreenshotErrorToast = toast.error(`Selected screenshot is too large. Use a smaller or a compressed version.`, {
                      autoClose: 10000,
                      hideProgressBar: false,
                    });
                    break
                  default:
                }
              }}
              header={({ openDialogue }) => (
                <DropArea>
                  {(isDrag) => (
                    <DropZone onClick={openDialogue}>
                      Drag or click to select file
                    </DropZone>
                  )}
                </DropArea>
              )}
            >
              {images => (
                <Stack direction="row" flexWrap="wrap" gap={2}>
                  {images.map(image => (
                    <Box position="relative" pt="12px">
                      <RUGCard image={image} />
                      {(image.source.startsWith("https://") || image.done)
                        ? <Typography fontWeight="bold" fontSize={12} color="black" my="10px" mx="20px">Published.</Typography>
                        : <Typography fontWeight="bold" fontSize={12} color="black" my="10px" mx="20px" mt="48px">Save profile to upload.</Typography>
                      }
                    </Box>
                  ))}
                </Stack>
              )}
            </RUG>
          )
        },
        { name: "video", title: "Video", description: () => <>Please paste the URL of your favourite YouTube or Vimeo video here.</>, type: "text", placeholder: "https://" },
        {
          name: "tags", title: "Tags", helpText: "Type a tag name, then press enter to continue adding more.", input: () => <ReactTagInput
            placeholder={true}
            tags={tags}
            maxTags={20}
            editable={false}
            readOnly={false}
            removeOnBackspace={true}
            onChange={(tags) => this.setState({ tags })}
          />
        },

        {
          heading: "Features", name: "heroFeatures", title: "Feature Set", description: () => <>List all of the features your solution provides e.g. “Administer Assessments”, “Give Student Feedback”, “Instant Messaging”. This data will help us surface your product to the right users and increase your pageviews.</>, helpText: 'Type a feature name, then press enter to continue adding more.',
          input: () => <ReactTagInput
            placeholder={true}
            tags={heroFeatures}
            maxTags={40}
            editable={false}
            readOnly={false}
            removeOnBackspace={true}
            onChange={(heroFeatures) => this.setState({ heroFeatures })}
          />
        },

        {
          name: "accessibility", title: "Accessibility Features", description: () => <>Choose the level of accessibility features embedded into your product e.g. colour combinations with good contrast.</>, input: () => <FormGroup>
            <SelectSearch
              options={[
                { name: '', value: '' },
                { name: 'Few to none', value: 'Few to none' },
                { name: 'Moderate', value: 'Moderate' },
                { name: 'Robust', value: 'Robust' },
              ]}
              onChange={handleFieldChange('accessibility')}
              value={accessibility}
            />
          </FormGroup>
        },
        {
          name: "languages", title: "Languages Supported", description: () => <>Select all languages that your product’s content and instructions support.</>, input: () => (
            <SortableSelect
              axis="xy"
              distance={4}
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
              isMulti
              options={languages.map(l => ({
                label: l.name,
                value: l.name,
              }))}
              onChange={languages => this.setState({ languages })}
              value={this.state.languages}
              components={{
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
              }}
              closeMenuOnSelect={false}
            />
          )
        },

        { heading: "Policies and Compliance", name: "dataPrivacy", title: "Privacy Policy", description: () => <>Please link to the web page that displays your privacy policy.</>, type: "text", placeholder: "https://" },
        { name: "terms", title: "Terms & Conditions", description: () => <>Please link to the web page that displays your terms and conditions.</>, type: "text", placeholder: "https://" },
        { name: "gdpr", title: "General Data Protection Regulation (GDPR)", description: () => <>Please link to the web page that displays your GDPR compliance.</>, type: "text", placeholder: "https://" },

        {
          heading: "Set Up and Support", name: "requirements", title: "Technical Requirements", description: () => <>What is needed to use your product effectively? Select all that apply.</>, input: () => <>
            {renderRequirements()}
            <p style={{ color: '#aaa', margin: '1rem 0 0.25rem' }}>Is there anything specific you want to add here?</p>
            <Editor
              editorState={techRequirements}
              onEditorStateChange={techRequirements => this.setState({ techRequirements })}
              toolbar={{ options: [] }}
              editorStyle={{ paddingTop: ".5rem" }}
            />
          </>
        },
        {
          name: "trialSetUp", title: "Set Up Time", description: () => <>How long does it take to set up and start using your product?</>, input: () => <Editor
            editorState={trialSetUp}
            onEditorStateChange={trialSetUp => this.setState({ trialSetUp })}
            toolbar={{ options: [] }}
            editorStyle={{ paddingTop: ".5rem" }}
          />
        },
        {
          name: "trainingSelection", title: "Training", description: () => <>What training do you provide to users?</>, input: () => <>
            {renderTrainingSelection()}
            <p style={{ color: '#aaa', margin: '1rem 0 0.25rem' }}>Is there anything specific you want to add here?</p>
            <Editor
              editorState={training}
              onEditorStateChange={training => this.setState({ training })}
              toolbar={{ options: [] }}
              editorStyle={{ paddingTop: ".5rem" }}
            />
          </>
        },

        { name: "training", title: "Training Details" },
        {
          name: "techSupport", title: "Support", description: () => <>What ongoing support do you provide to users?</>, input: () => <>
            {renderTechSupport()}
            <p style={{ color: '#aaa', margin: '1rem 0 0.25rem' }}>Is there anything specific you want to add here?</p>
            <Editor
              editorState={supportDetails}
              onEditorStateChange={supportDetails => this.setState({ supportDetails })}
              toolbar={{ options: [] }}
              editorStyle={{ paddingTop: ".5rem" }}
            />
          </>
        },
        { name: "supportDetails", title: "Support Details" },

        {
          heading: "Home Learning / Parent Access", name: "availableToParents", title: "Parent Availability", description: () => <>Is your product available to parents?</>, input: () => <><FormGroup>
            <SelectSearch
              options={[{ name: '', value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }]}
              onChange={handleFieldChange('availableToParents')}
              value={availableToParents}
            />
          </FormGroup>
            <p style={{ color: '#aaa', margin: '1rem 0 0.25rem' }}>Is there anything specific you want to add here?</p>
            <Editor
              editorState={availableToParentsDetails}
              onEditorStateChange={availableToParentsDetails => this.setState({ availableToParentsDetails })}
              toolbar={{ options: [] }}
              editorStyle={{ paddingTop: ".5rem" }}
            /></>
        },

        {
          name: "initiator", title: "Who Should Initiate Use", description: () => <>Can parents start using the product, or do they need someone else to set it up?</>, input: () => <FormGroup>
            <SelectSearch
              options={[
                { name: '', value: '' },
                { name: 'Anyone can create the account', value: 'Anyone can create the account' },
                { name: 'Parents can create the account', value: 'Parents can create the account' },
                { name: 'School must create the account', value: 'School must create the account' },
                { name: 'Teacher must create the account', value: 'Teacher must create the account' },
              ]}
              onChange={handleFieldChange('initiator')}
              value={initiator}
            />
          </FormGroup>
        },

        {
          name: "supervision", title: "Parent Supervision Required", description: () => <>Do parents need to guide children through lessons? Are there any safeguarding needs?</>, input: () => <FormGroup>
            <SelectSearch
              options={[{ name: '', value: '' }, { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }]}
              onChange={handleFieldChange('supervision')}
              value={supervision}
            />
          </FormGroup>
        },

        { heading: "Pricing", name: "purchaseModels", title: "Purchase Models", description: () => <>What pricing model(s) does {product.name} provide?<br />IMPORTANT: If you select 'Free' or 'Freemium', you are stating that your solution is completely free <span style={{ textDecoration: 'underline' }}>forever</span>.</>, input: renderPurchaseModels },
        {
          name: "priceStartingFrom", title: "Price Starting From", description: () => <>What is the lowest price available?</>, input: () => (<>
            <PriceStartingFrom>
              <SelectSearch
                options={[{ name: '', value: '' }, { name: '£', value: '£' }, { name: '$', value: '$' }, { name: '€', value: '€' }]}
                onChange={currency => this.setState({ priceStartingFrom: { ...priceStartingFrom, currency } })}
                value={priceStartingFrom.currency}
              />
              <Input
                type="number"
                onChange={(e) => this.setState({ priceStartingFrom: { ...priceStartingFrom, amount: parseFloat(e.target.value) } })}
                value={priceStartingFrom.amount}
              />
              <SelectSearch
                options={[{ name: '', value: '' }, { name: 'per pupil', value: 'per-pupil' }, { name: 'per user', value: 'per-user' }, { name: 'per hour', value: 'hour' }, { name: 'per month', value: 'month' }, { name: 'per year', value: 'year' }, { name: 'one-off fee', value: 'one-off' }]}
                onChange={period => this.setState({ priceStartingFrom: { ...priceStartingFrom, period } })}
                value={priceStartingFrom.period}
              />
            </PriceStartingFrom>
            <Typography variant='body2' fontSize={13} style={{ color: 'grey.400', margin: '1rem 0 0.25rem' }}>What additional information can you share that prospective customers are usually looking for? Do you have any group pricing? Can you share specific pricing plans?</Typography>
            <Editor
              editorState={additionalPricingInformation}
              onEditorStateChange={additionalPricingInformation => this.setState({ additionalPricingInformation })}
              toolbar={{ options: [] }}
              editorStyle={{ paddingTop: ".5rem" }}
            />
          </>
          )
        },
        { name: "additionalPricingInformation", title: "Price" },
        { name: "specialOffer", title: "Special Offers", description: () => <>Are you running any promotions? Can you offer something exclusive to EdTech Impact users? Special offers will be highlighted on your profile page.</>, type: "text" },
        { name: "requirements", title: "Requirements" },
        { name: "availableToParentsDetails", title: "Parent Availability Details" },
      ]
    );

    return profileFields.map(field => {
      return ({
        ...field,
        ...profileFieldDefinitions.find(definition => definition.name === field.name),
      });
    })
  }

  handleScrape = async (fields = 'all') => {
    this.setState({ AIIsLoading: true })

    try {
      const { name, homepage } = this.state
      const response = await fetch(
        `https://schools-staging.edtechimpact.com/api/products/scrape/?url=${homepage}&name=${name}&fields=${fields === 'all' ? 'all' : 'descriptions'}`,
        {
          mode: 'cors',
          credentials: 'include',
        },
      )
      const data = await response.json()

      console.log(data)

      if (response.status != 200) {
        toast.error(`Error fetching data`)
        return
      }

      if (fields === 'story') this.setState({ story: EditorState.createWithContent(convertFromHTML(data.story)) })
      if (fields === 'shortDescription') this.setState({ shortDescription: data.shortDescription })
      else this.setStateFromProps(data, this.state)

    } catch (error) {
      toast.error(`Error.`)
      console.error(error)
    } finally {
      this.setState({ AIIsLoading: false })
    }
  }

  render() {
    const { createPending, updatePending, product } = this.props;
    const { logo, images, showErrors, AIIsLoading } = this.state;

    if (!profileFields || !logo || !images) return null;

    return (
      <StyledProductAdmin fluid>
        {(updatePending || createPending) && <LoadingScreen><Loader /></LoadingScreen>}

        <Row className="align-items-center">
          <Col lg={7}>
            <div className="page-title-box">
              <Typography variant='h2'>
                Product Details
                {this.props.isAdmin &&
                  <LoadingButton
                    sx={{ ml: 3 }}
                    variant="outlined"
                    color="primary"
                    onClick={async () => await this.handleScrape()}
                    loading={AIIsLoading}
                    disabled={AIIsLoading}
                    size='small'
                  >
                    Complete with AI
                  </LoadingButton>
                }
              </Typography>
              <Typography>
                This is your public profile page, designed to tell the world why your EdTech product exists.
              </Typography>
              <Typography>The information you add here will wrap around your customer reviews and impact metrics, creating a compelling business case as to why your product is worth the investment.
              </Typography>
              <MuiAlert severity='info'>
                <AlertTitle>
                  Tips and Guidelines
                </AlertTitle>
                <ul style={{ paddingInlineStart: '20px' }}>
                  <li>
                    Remember: more transparency means more trust, so don’t hold back.
                  </li>
                  <li>
                    Inclusion of external URLs risks removal of your profile. Please review our <Link href='https://edtechimpact.com/terms/' onClick={() => this.props.tracking.trackEvent({ event: 'click.profile.terms-and-conditions' })} target='_blank'>Terms and Conditions</Link> to ensure full compliance.
                  </li>
                </ul>
              </MuiAlert>
            </div>
          </Col>
        </Row>

        {this.buildProfileFields(product)
          .filter(field => field.type || field.input)
          .filter(field => !field.forAdmin || this.props.isAdmin)
          .map(field => {
            return (
              <React.Fragment key={field.name}>
                {field.heading &&
                  <Row className="align-items-center">
                    <Col sm={6}>
                      <div className="page-title-box">
                        <Typography variant='h3'>{field.heading}</Typography>
                      </div>
                    </Col>
                  </Row>
                }
                <Question
                  AIIsLoading={AIIsLoading}
                  handleScrape={this.handleScrape}
                  showErrors={showErrors}
                  disabledProps={{
                    fieldIsPremium: field.isPremium,
                    productIsPremium: this.props.product.isPremium,
                    fieldForAdmin: field.forAdmin || false,
                    userIsAdmin: this.props.isAdmin,
                  }}
                  disabled={field.isPremium && !this.props.product.isPremium && !this.props.isAdmin}
                  required={field.required}
                  title={field.title}
                  name={field.name}
                  value={this.state[field.name]}
                  helpText={field.helpText}
                  Description={field.description}
                  Input={
                    field.type === 'text' ?
                      () => <Input
                        required={field.required}
                        placeholder={field.placeholder}
                        value={this.state[field.name]}
                        onChange={(e) => this.setState({ [field.name]: e.target.value })}
                        type="text"
                      />
                      : field.type === 'textarea' ?
                        () => <Input
                          required={field.required}
                          placeholder={field.placeholder}
                          value={this.state[field.name]}
                          onChange={(e) => this.setState({ [field.name]: e.target.value })}
                          type="textarea"
                          style={{ height: '4rem' }}
                        />
                        : field.input
                  }
                />
              </React.Fragment>
            );
          })
        }

        <Row>
          <Col>
            <Button onClick={this.handleSubmit()} color="primary">
              Save and Publish
            </Button>
          </Col>
        </Row>

      </StyledProductAdmin>
    );
  }
}

export const Question = ({
  AIIsLoading,
  handleScrape,
  title,
  Description,
  Alert,
  description,
  tip,
  helpText,
  disabled,
  disabledProps = {},
  Input,
  width = 8,
  style = {},
  required,
  name,
  showErrors,
  value,
}) => {
  return (
    <Row id={name}>
      <Col lg={{ size: width, offset: 0 }}>
        {disabled
          ? (
            <div style={{ marginBottom: '0.25rem' }}>
              <PremiumFeature />
            </div>
          )
          : (
            <div style={{ marginBottom: '0.25rem' }}>
              {/* Ensure it is clear a certain field is admin-only. */}
              {disabledProps.fieldForAdmin && disabledProps.userIsAdmin && (
                <AdminFeature />
              )}
              {/* Tell admins visually what's blocked to pro users even if they can access it and use it just fine. */}
              {disabledProps.fieldIsPremium && !disabledProps.productIsPremium && disabledProps.userIsAdmin && (
                <PremiumFeature />
              )}
            </div>
          )}
        <Card sx={{ ...style, position: 'relative', mb: 8, overflow: 'visible' }}>
          <CardContent>
            {title && <Typography variant="h5" component="h3" sx={{ marginBottom: !!Description ? 2 : 4, color: showErrors && hasError(required, disabled, value) && 'red' }}>
              <span>{title}</span>{required && !disabled && ' *'}

              {['story', 'shortDescription'].includes(name) &&
                <LoadingButton
                  sx={{ ml: 3 }}
                  variant="outlined"
                  color="primary"
                  onClick={async () => await handleScrape(name)}
                  loading={AIIsLoading}
                  disabled={AIIsLoading}
                  size='small'
                >
                  Complete with AI
                </LoadingButton>
              }
            </Typography>}
            {!!Alert && Alert}
            {Description && <Typography variant='body2' mt={-1} mb={4}>{Description()}</Typography>}
            {description && <Typography variant='body2' mt={-1} mb={4}>{description}</Typography>}
            {tip && <MuiAlert severity='info'><span dangerouslySetInnerHTML={{ __html: `${tip}` }}></span></MuiAlert>}
            {Input()}
            {helpText && <Typography variant='caption'>{helpText}</Typography>}
          </CardContent>
          {disabled && <div style={{ position: 'absolute', zIndex: 1, inset: 0, backgroundColor: 'rgba(255,255,255,0.6)' }} />}
        </Card>
      </Col>
    </Row>
  );
}

const PriceStartingFrom = styled.div`
  display: flex;

  .select-search {
    flex: 0 0 130px;

    &:first-child {
      flex: 0 0 80px;
      margin-right: 10px;
    }
  }

  input {
    flex: 0 0 110px;
    margin-right: 10px;
  }
`;

const DropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #aaa;
  width: 100%;
  min-height: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  padding: 0.5rem;
  cursor: pointer;
`;

export const QuestionTitle = styled.h5`
  margin-bottom: 0.5rem;
`;

export const QuestionDescription = styled.p`
  margin-bottom: 1rem;
  margin-top: -0.25rem;
  color: #aaa;
`;

export const LoadingScreen = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(255,255,255,0.6);
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: ${({ compact }) => compact ? 0 : '33vh'};
`;

const StyledProductAdmin = styled(Container)`
  position: relative;
  margin-bottom: 3rem;

  .form-check {
    display: inline-block;
    width: 12rem;
  }

  .rug .rug-items.__card {
    min-height: 0;
    grid-template-columns: repeat(auto-fill, minmax(126px, 1fr));
  }

  .rug-card {
    width: 126px;
    height: 126px;
    background: transparent;
    border: 1px solid #ccc;
  }

  .rug-card .rug-card-name {
    background: transparent;
  }

  .rug-card .rug-card-upload-button {
    display: none;
  }

  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
  };
  
  .thumbInner {
    display: flex;
    minWidth: 0;
    overflow: hidden;
  };
  
  .img {
    display: block;
    width: auto;
    height: 100%;
  };
`;



const LabelText = styled.span`
  font-weight: normal;
`;

const PremiumLink = styled.a`
  background-color: #0482a2;
  color: white;
  border-radius: .25rem;
  padding: 4px 8px;
  font-weight: normal;
  font-size: .8rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  
  transition: background-color .25s ease-in;

  display: inline-flex;
  flex-direction: row;
  gap: .25rem;
  align-items: center;

  &:hover {
    transition: background-color .25s ease-out;
    background-color: #024657;
    color: white;
  }
`;

const mapStateToProps = state => {
  const { updatePending, createPending, product } = state.Product;
  return { updatePending, createPending, product };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchSubmit: actions.updateProductAction,
      dispatchCreateProduct: actions.createProductAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withTracking(),
)(ProductAdmin);
