import * as Cookies from 'js-cookie';
import moment from "moment";

import { Suspense, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import * as actions from './actions';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTracking } from 'react-tracking';

import { Close } from '@mui/icons-material';
import { Box, CardContent, IconButton, LinearProgress, Alert as MuiAlert, Card as MuiCard, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import styled from 'styled-components';

import LoadingSpinner from '../../components/Loader';
import Dashboard from '../Dashboard';
import AdminStats from '../SiteAdmin/AdminStats';
import VisitorStats from '../SiteAdmin/VisitorStats';
import { completedItemCount, isChecklistCompleted, requiredListItems } from './GetStarted';
import ReviewsIframe from './ReviewsIframe';

import usePrevious from '../../helpers/usePrevious';
import useAuth from "../../pages/Authentication/useAuth";
import { bp } from '../../utils/mq';

import { authHost } from "../Authentication/Login";

const InviteUpload = lazy(() => import('./InviteUpload'));
const InviteTest = lazy(() => import('./InviteTest'));
const Invitations = lazy(() => import('./Invitations'));
const InvitationPreview = lazy(() => import('./InvitationPreview'));
const Profile = lazy(() => import('./Profile'));
const Analytics = lazy(() => import('./Analytics'));
const GetStarted = lazy(() => import('./GetStarted'));
const ReviewCollection = lazy(() => import('./ReviewCollection'));
const ReviewCollectionAutomatic = lazy(() => import('./ReviewCollectionAutomatic'));
const ReviewExport = lazy(() => import('./ReviewExport'));
const Admin = lazy(() => import('./Admin/index'));
const PricePerLeadCalculator = lazy(() => import('./PricePerLeadCalculator'));
const Widgets = lazy(() => import('./Widgets'));
const Badges = lazy(() => import('./MarketingAssets/Badges'));
const Awards = lazy(() => import('./Awards/index'));
const Evidence = lazy(() => import('./Evidence'));
const ImpactMetrics = lazy(() => import('./ImpactMetrics'));
const EmailWidgets = lazy(() => import('./EmailWidgets'));
const Reviews = lazy(() => import('./Reviews'));
const Notifications = lazy(() => import('./Notifications'));
const UserManagement = lazy(() => import('./UserManagement'));
const Categories = lazy(() => import('./Categories'));

// Leads
const LeadsIndex = lazy(() => import('./Leads/LeadsIndex'));
const LeadsPreferences = lazy(() => import('./LeadsPreferences/index'));
const LeadsPrices = lazy(() => import('./LeadsPrices'));
const LeadReplyTemplates = lazy(() => import('./LeadReplyTemplates'));

// Prospects
const BuyerIntentIndex = lazy(() => import('pages/SiteAdmin/BuyerIntent/BuyerIntentIndex'));
const BuyerIntentPreferences = lazy(() => import('pages/SiteAdmin/BuyerIntent/BuyerIntentPreferences'));

const PricingPlansPage = lazy(() => import('./PricingPlansPage'));

// Billing
const BillingPage = lazy(() => import('./BillingPage'));
const PurchaseCredits = lazy(() => import('./PurchaseCredits'));

const AdvancedSettingsPage = lazy(() => import('./AdvancedSettingsPage'));
const HealthCheck = lazy(() => import('./HealthCheck'));

const ProductAdmin = ({
  productPending,
  reviewsPending,
  invitesPending,
  leadsPending,
  products,
  product,
  reviews,
  match,
  location,
  dispatchConfigRequest,
  dispatchProductsRequest,
  dispatchInvitesRequest,
  dispatchGetProduct,
  dispatchReviewsRequest,
  dispatchLeadsRequest,
  tracking,
  dispatchSubmit,
}) => {
  const { currentUser } = useAuth();
  const isAdmin = currentUser?.isAdmin

  const prevProduct = usePrevious(product);
  const [topupReminderOpen, setTopupReminderOpen] = useState(false)
  const [freeLeadsReminderOpen, setFreeLeadsReminderOpen] = useState(false)
  const [softAnnouncementConfig, setSoftAnnouncementConfig] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  })
  const theme = useTheme();
  const dialogButtonFullWidth = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(
    function getProductRelatedData() {
      if (
        // Have products from API, or slug in URL
        (products || match.params.slug)
        // Changed to a different product
        && (!prevProduct || match.params.slug !== prevProduct.slug)
      ) {
        const slug = match.params?.slug || product?.slug || products[0]?.slug;

        if (slug) {
          !productPending && dispatchGetProduct(slug);

          if (slug !== 'add-listing') {
            !reviewsPending && dispatchReviewsRequest({ slug });
            !invitesPending && dispatchInvitesRequest({ slug });
            !leadsPending && dispatchLeadsRequest({ slug });
          }
        }

      }
    },
    [match.params.slug, product, products]
  );

  useEffect(
    function trackProductUpdates() {
      if (product && prevProduct?.slug === product.slug) {
        tracking.trackEvent({ event: 'update-product' });
      }
    },
    [product]
  );

  if (!products || productPending) {
    return (
      <div style={{ height: '80vh' }}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          containerId="pendingProductAdminToast"
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', height: '100%', width: '100%' }}>
          <LoadingSpinner />
        </Box>
      </div>
    );
  }

  //  No (actual) products found.
  if (products.length <= 1 && !product) {
    window.location.replace(encodeURI(`${authHost}/account/`))
  }

  const onlyProduct = products[0];

  // No slug, but have exactly one (actual) product.
  if (!match.params.slug && products.length === 2) {
    if (!isChecklistCompleted(product, reviews?.data) && products.length > 1 && !product) {
      return <Redirect to={`/products/${match.params.slug || products[0].slug}/get-started`} />
    }

    return <Redirect to={`/products/${onlyProduct.slug}/dashboard`} />;
  }

  // Customer has at least one actual product, but no product data could be fetched.
  if (products.length > 1 && !product) {
    // ?
    if (match.params.slug === 'add-listing') {
      window.location.replace(encodeURI(`${authHost}/account/`))
    }

    if (location.pathname) return <Redirect to={location.pathname} />;
    return <Redirect to={`/products/${match.params.slug || onlyProduct.slug}/dashboard`} />;
  }

  const { isPremium, unlimitedLeads = false, pricePerLeadSince = null } = product || {};

  // Redirect old price list and old purchase credits to new pages.
  if (location.pathname === `/products/${product.slug}/leads/purchase`) {
    return <Redirect to={`/products/${product.slug}/billing#details--balance`} />
  }

  // Redirect reply templates to leads index
  if (location.pathname === `/products/${product.slug}/leads/templates`) {
    return <Redirect to={`/products/${product.slug}/leads`} />
  }

  if (location.pathname === `/products/${product.slug}/leads/prices`) {
    return <Redirect to={`/products/${product.slug}/billing`} />
  }

  /**
   * Redirect removed survey page to manual review collection page.
   * @see https://trello.com/c/Y5q3s42C/950-customer-survey-tab-not-loading-form-https-myedtechimpactcom-products-unifrog-reviews-survey
   */
  if (location.pathname === `/products/${product.slug}/reviews/survey`) {
    return <Redirect to={`/products/${product.slug}/reviews/manual-collection`} />
  }

  /**
   * Redirect health checks to home page.
   * @see https://trello.com/c/fgHqfMIj/962-urgent-remove-health-checks-from-vendor-dashboard-from-profile-menu-and-on-the-dashboard-home-page
   */
  if ([
    '/health-check/researched-impact',
    '/health-check/compliance-safety',
    '/health-check/learning-engagement',
  ].includes(location.pathname)) {
    <Redirect to='/dashboard' />
  }

  const trackSoftAnnouncementLink = () => {
    tracking.trackEvent({
      event: 'soft-announcement.cta.click:compare-bundles',
    })
    handleSoftAnnouncementClose()
  }

  // Soft announcements.
  const handleSoftAnnouncementOpen = () => {
    setSoftAnnouncementConfig({ ...softAnnouncementConfig, open: true })
  }

  const handleSoftAnnouncementClose = () => {
    setSoftAnnouncementConfig({ ...softAnnouncementConfig, open: false })

    tracking.trackEvent({
      event: 'soft-announcement.dismiss',
    })

    // Set a cookie so they don't see it again.
    const now = moment().utc()
    // 3 weeks from last date of launch/opt-in.
    const lastDate = moment("2023-08-15T23:59:59.999Z").utc()
    Cookies.set(`${product.supabaseId}_SoftAnnouncementRead`, true, {
      // Whatever number of days it is to 15 August 2023 from now.
      expires: Number(moment.duration(lastDate.diff(now)).asDays().toFixed(0)) || 1,
      domain: document.location.hostname,
    })
  }

  // Criteria will change with every announcement and its needs.
  if (pricePerLeadSince
    && moment(pricePerLeadSince).isAfter("2023-07-04T00:00:00.000Z")
    && moment(pricePerLeadSince).isBefore("2023-07-25T00:00:00.000Z")
    // Do not show this any longer after 3 weeks from the last opt-in date.
    && moment().isBefore("2023-08-15T23:59:59.999Z")
    && !Cookies.get(`${product.supabaseId}_SoftAnnouncementRead`)
    && !softAnnouncementConfig.open
    // Exclude products with unlimited leads.
    && !unlimitedLeads
  ) {
    handleSoftAnnouncementOpen()
  }



  return (
    <StyledProductAdmin fluid>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        containerId="defaultProductAdminToast"
      />

      {!product?.published &&
        <Container fluid className="mt-3">
          <Row>
            <Col>
              <MuiAlert severity="error" variant='standard'>
                Your product is currently unpublished pending review by the EdTech Impact team.
              </MuiAlert>
            </Col>
          </Row>
        </Container>
      }


      <Snackbar
        // 5 minutes
        autoHideDuration={300000}
        anchorOrigin={{ vertical: softAnnouncementConfig.vertical, horizontal: softAnnouncementConfig.horizontal }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="#000"
            onClick={handleSoftAnnouncementClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
        open={softAnnouncementConfig.open}
        onClose={handleSoftAnnouncementClose}
        message={<Typography mb={0}><strong>Power up!</strong> Introducing our new message bundles with tailored pricing. <Link to={`/products/${product.slug}/billing#details--balance`} onClick={trackSoftAnnouncementLink}>Compare bundles</Link></Typography>}
        key={softAnnouncementConfig.vertical + softAnnouncementConfig.horizontal}
        disableWindowBlurListener={true}
      />

      {/* If they are a paid client and have low or minimum balance, do not show the onboarding checklist card. */}
      {!isPremium && !isChecklistCompleted(product, reviews?.data) && !location.pathname.includes('get-started') && location.pathname.endsWith(`/products/${product.slug}/dashboard`) &&
        <Container fluid className="mt-3">
          <Row>
            <Col lg={6} className='d-flex align-items-center'>
              <MuiCard variant='outlined'>
                <CardContent py={4}>
                  <Typography>
                    You've completed <b>{completedItemCount(product, reviews?.data)}</b> out of{" "}
                    <b>{requiredListItems(product).length}</b> <Link to={`/products/${product.slug}/get-started`}>onboarding steps</Link>.
                  </Typography>

                  <LinearProgress
                    value={(completedItemCount(product, reviews?.data) / requiredListItems(product).length) * 100}
                    variant='determinate'
                  />
                </CardContent>
              </MuiCard>
            </Col>
          </Row>
        </Container>
      }

      <Suspense fallback={<LoadingSpinner isPremium={isPremium} />}>
        <Switch>
          <AdminRoute
            path="/products/:slug/admin"
            exact
            render={() => <Admin isPremium={isPremium} />}
          />

          <AdminRoute
            path="/products/:slug/price-per-lead-calculator"
            exact
            render={() => <PricePerLeadCalculator />}
          />

          <AdminRoute
            path="/products/:slug/admin-stats"
            exact
            render={() => <AdminStats isPremium={isPremium} />}
          />

          <AdminRoute
            path="/products/:slug/visitor-stats"
            exact
            render={() => <VisitorStats isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug"
            exact
            render={() => <Redirect to={`/products/${product.slug}/dashboard`} />}
          />

          <Route
            path="/products/:slug/health-check/researched-impact"
            exact
            render={() => <HealthCheck isPremium={isPremium} product={product} form="researched-impact" />}
          />
          <Route
            path="/products/:slug/health-check/compliance-safety"
            exact
            render={() => <HealthCheck isPremium={isPremium} product={product} form="compliance-safety" />}
          />
          <Route
            path="/products/:slug/health-check/learning-engagement"
            exact
            render={() => <HealthCheck isPremium={isPremium} product={product} form="learning-engagement" />}
          />
          <Route
            path="/products/:slug/health-check"
            exact
            render={() => <HealthCheck isPremium={isPremium} product={product} form="researched-impact" />}
          />
          <Route
            path="/products/:slug/dashboard"
            exact
            render={() => <Dashboard isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/get-started"
            exact
            render={() => reviews && <GetStarted product={product} reviews={reviews?.data} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/analytics"
            exact
            render={() => <Analytics product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/manual-collection"
            exact
            render={() => <ReviewCollection isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/automatic-collection"
            exact
            render={() => <ReviewCollectionAutomatic isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/export"
            exact
            render={() => <ReviewExport isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/profile"
            exact
            render={() => <Profile isPremium={isPremium} isAdmin={isAdmin} />}
          />
          <Route
            path="/products/:slug/impact-metrics"
            exact
            render={() => <ImpactMetrics isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/categories"
            exact
            render={() => <Categories isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/evidence"
            exact
            render={() => <Evidence />}
          />
          <Route
            path="/products/:slug/widgets"
            exact
            render={() => <Widgets product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews-page"
            exact
            render={() => (
              <ReviewsIframe product={product} isPremium={isPremium} />
            )}
          />
          <Route
            path="/products/:slug/marketing-assets"
            exact
            render={() => <Badges product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/awards"
            exact
            render={() => <Awards product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/email-widgets"
            exact
            render={() => (
              <EmailWidgets product={product} isPremium={isPremium} />
            )}
          />
          <Route
            path="/products/:slug/reviews/your-reviews"
            exact
            render={() => <Reviews isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/invitations"
            exact
            render={() => <Invitations isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/invite-email"
            exact
            render={() => <InvitationPreview isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/upload-invites"
            exact
            render={() => <InviteUpload isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/test-invite"
            exact
            render={() => <InviteTest isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/admin/notifications"
            exact
            render={() => <Notifications isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/admin/users"
            exact
            render={() => <UserManagement isPremium={isPremium} product={product} submit={dispatchSubmit} />}
          />
          <Route
            path="/products/:slug/leads"
            exact
            render={() => (
              <LeadsIndex isAdmin={isAdmin} isPremium={isPremium} />
            )}
          />
          <Route
            path="/products/:slug/leads/preferences"
            exact
            render={() => <LeadsPreferences isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/leads/prices"
            exact
            render={() => <LeadsPrices isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/leads/templates"
            exact
            render={() => <LeadReplyTemplates isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/leads/purchase"
            exact
            render={() => <PurchaseCredits isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/visitor-activity"
            exact
            render={() => <BuyerIntentIndex isAdmin={isAdmin} product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/visitor-activity/preferences"
            exact
            render={() => <BuyerIntentPreferences isAdmin={isAdmin} product={product} isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/subscription"
            exact
            render={() => <PricingPlansPage isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/billing"
            exact
            render={() => <BillingPage isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/admin/advanced/"
            exact
            render={() => <AdvancedSettingsPage isPremium={isPremium} />}
          />

        </Switch>
      </Suspense>
    </StyledProductAdmin>
  );
}


const AdminRoute = props => {
  const { currentUser } = useAuth();
  if (!currentUser || !currentUser.isAdmin) return null;
  return <Route {...props} />;
}

const StyledProductAdmin = styled(Container)`
  position: relative;
  margin-bottom: 3rem;
  padding: 0 0.25rem;

  @media ${bp.md} {
    padding: 0 0.75rem;
  }
`;

const mapStateToProps = state => {
  const { product, products, productPending, invitesPending, leadsPending, productsPending, updatePending, reviewsPending, reviews } = state.Product;
  const { preselectedSubscription } = state.App;
  return { preselectedSubscription, product, products, productPending, invitesPending, leadsPending, productsPending, updatePending, reviewsPending, reviews };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchConfigRequest: actions.configRequestAction,
      dispatchProductsRequest: actions.productsRequestAction,
      dispatchGetProduct: actions.getProduct,
      dispatchReviewsRequest: actions.reviewsRequestAction,
      dispatchInvitesRequest: actions.invitesRequestAction,
      dispatchLeadsRequest: actions.leadsRequestAction,
      dispatchSubmit: actions.updateProductAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withTracking(),
)(ProductAdmin);
